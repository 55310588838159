import React from 'react';
import classNames from 'classnames';
import Heading from '@afs/components/Heading';
import LazyImage from '../../atoms/LazyImage';
import styles from './styles.module.scss';
export interface PbsaProviderTrustBarProps {
  className?: string;
}
const providers = [{
  name: 'Collegiate UK',
  className: styles.collegiate,
  image: {
    standard: 'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/home-page/provider-logos/collegiate-1x.png',
    retina: 'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/home-page/provider-logos/collegiate-2x.png'
  }
}, {
  name: 'CRM Students',
  className: styles.crm,
  image: {
    standard: 'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/home-page/provider-logos/crm-1x.png',
    retina: 'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/home-page/provider-logos/crm-2x.png'
  }
}, {
  name: 'Fresh Student Living',
  className: styles.fresh,
  image: {
    standard: 'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/home-page/provider-logos/fresh-1x.png',
    retina: 'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/home-page/provider-logos/fresh-2x.png'
  }
}, {
  name: 'Homes for Students',
  className: styles.hfs,
  image: {
    standard: 'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/home-page/provider-logos/hfs-1x.png',
    retina: 'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/home-page/provider-logos/hfs-2x.png'
  }
}, {
  name: 'Host',
  className: styles.host,
  image: {
    standard: 'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/home-page/provider-logos/host-1x.png',
    retina: 'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/home-page/provider-logos/host-2x.png'
  }
}, {
  name: 'iQ Student Accommodation',
  className: styles.iq,
  image: {
    standard: 'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/home-page/provider-logos/iq-1x.png',
    retina: 'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/home-page/provider-logos/iq-2x.png'
  }
}, {
  name: 'Now Students',
  className: styles.now,
  image: {
    standard: 'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/home-page/provider-logos/now-1x.png',
    retina: 'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/home-page/provider-logos/now-2x.png'
  }
}, {
  name: 'Yugo',
  className: styles.yugo,
  image: {
    standard: 'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/home-page/provider-logos/yugo-1x.png',
    retina: 'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/home-page/provider-logos/yugo-2x.png'
  }
}];
const PbsaProviderTrustBar = ({
  className
}: PbsaProviderTrustBarProps) => {
  return <section className={classNames(styles.container, className)} data-sentry-component="PbsaProviderTrustBar" data-sentry-source-file="index.tsx">
      <Heading className={styles.heading} level={2} data-sentry-element="Heading" data-sentry-source-file="index.tsx">
        Trusted by 20,000+ student accommodation providers big and small
      </Heading>
      <ul className={styles.providers}>
        {providers.map(p => <li className={classNames(styles.provider, p.className)} key={p.name}>
            <LazyImage alt={p.name} dataSrc={p.image.standard} dataSrcSet={`${p.image.standard} 1x, ${p.image.retina} 2x`} />
          </li>)}
      </ul>
    </section>;
};
export default PbsaProviderTrustBar;