import Location from '../types/Location'
import CountryCode from '../types/CountryCode'
import PropertyType from '../types/PropertyType'
import Currency from '../types/Currency'

export enum SearchFilterOccupancy {
  wholeProperty = 'wholeProperty',
  min = 'min',
  exact = 'exact',
}

export interface MoveInDates {
  moveInFrom?: string
  moveInTo: string
}

export interface ContractLength {
  minContactLength?: number
  maxContractLength: number
}

export interface SearchBuildCriteria {
  geo: boolean
  page?: number
  beds?: number
  occupancy?: SearchFilterOccupancy
  instantBook?: boolean
  propertyTypes?: PropertyType[]
  bills?: SearchBillOption[]
  minPrice?: number
  maxPrice?: number
  moveInFrom?: string
  moveInTo?: string
  minContractLength?: number | null
  maxContractLength?: number | null
  sortBy?: string
  order?: string
  location?: string
  area?: string
  latitude?: number
  longitude?: number
  radius?: number
  countryCode: CountryCode
  hasGeoCoordinates: boolean
  locationForSearch: string
  afsLocations: Location[]
  withLocation: (
    selectedLocation: string,
    geoCoordinates?: GeoCoordinates
  ) => SearchBuildCriteria
  withCountryCode: (selectedCountryCode: CountryCode) => SearchBuildCriteria
  withInstantBook: (selectedInstantBook?: boolean) => SearchBuildCriteria
  withPropertyTypes: (
    selectedPropertyTypes: PropertyType[]
  ) => SearchBuildCriteria
  withBills: (selectedBills: SearchBillOption[]) => SearchBuildCriteria
  withMinPrice: (selectedMinPrice: number) => SearchBuildCriteria
  withMaxPrice: (selectedMaxPrice: number) => SearchBuildCriteria
  withBeds: (selectedBeds: number) => SearchBuildCriteria
  incrementBedsByOne: () => SearchBuildCriteria
  decrementBedsByOne: () => SearchBuildCriteria
  withOccupancy: (
    selectedOccupancy: SearchFilterOccupancy
  ) => SearchBuildCriteria
  withMoveInDates: (selectedMoveInDates: MoveInDates) => SearchBuildCriteria
  withContractLength: (
    selectedContractLength: ContractLength
  ) => SearchBuildCriteria
  withFilters: (
    filters: Omit<SearchBarFilterValues, 'location' | 'beds' | 'price'>
  ) => SearchBuildCriteria
  withPage: (page: number) => SearchBuildCriteria
  sortByPriceAscending: () => SearchBuildCriteria
  sortByPriceDescending: () => SearchBuildCriteria
  sortByDefault: () => SearchBuildCriteria
}

export interface PropertyAlertFormCriteria {
  location: string
  numberOfBedrooms: number
  occupancy: SearchFilterOccupancy
  minPrice: { amount: number; currency: Currency }
  maxPrice: { amount: number; currency: Currency }
  propertyTypes: PropertyType[]
  coordinates: Pick<GeoCoordinates, 'latitude' | 'longitude'>
  radius: number
  geo: boolean
}

export enum SearchBillOption {
  gas = 'gas',
  electricity = 'electricity',
  water = 'water',
  internet = 'internet',
  none = 'none',
}

export interface GeoCoordinates {
  latitude: number
  longitude: number
  radius: number
}

export interface SearchBarFilterValues
  extends Pick<
    SearchBuildCriteria,
    | 'instantBook'
    | 'moveInFrom'
    | 'moveInTo'
    | 'minContractLength'
    | 'maxContractLength'
    | 'bills'
    | 'propertyTypes'
  > {
  location?: {
    location: string
    geoCoordinates: GeoCoordinates
  }
  beds?: {
    numberOfBedrooms: number
    entirePlace: boolean
  }
  price?: {
    maxPrice: number
    minPrice: number
  }
}
